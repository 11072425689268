import { useState } from "react";

import { Eye, EyeClosed } from "@phosphor-icons/react";

const PasswordInput = (props) => {
  const [showPassword, setShowPassword] = useState(false);

  const {
    inputStyle,

    name,
    id,
    placeholder,
    value,
    onChange = () => {},
    onBlur = () => {},
    onFocus = () => {},
    hasError = false,
    errorMessage = "error",
  } = props;

  const handlePassword = () => {
    setShowPassword((cur) => !cur);
  };

  return (
    <div className={``}>
      <div
        className={`flex items-center  justify-between w-full rounded-md  ${inputStyle}`}
      >
        <input
          className={`w-full focus:outline-none `}
          id={id}
          type={`${showPassword ? "text" : "password"}`}
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
        />
        <span className="cursor-pointer" onClick={() => handlePassword()}>
          {!showPassword && <EyeClosed size={24} weight="light" />}
          {showPassword && <Eye size={24} weight="light" />}
        </span>
      </div>
      <p
        className={`text-xs mt-1 text-red-500 ${hasError ? "block" : "hidden"}`}
      >
        {errorMessage}
      </p>
    </div>
  );
};

export default PasswordInput;
