// layout
import Header from "./layout/Header/Header";
// portals
import SelectTypeModal from "./pages/Register/Sales Force Forms/No Type/SelectTypeModal";

import RegisterationRoute from "./pages/Register/RegisterationRoute";

// context
import ContextProvider from "./context/ContextProvider";

const App = () => {
  return (
    <ContextProvider>
      <Header />
      {/* portals */}
      <SelectTypeModal />

      <RegisterationRoute />
    </ContextProvider>
  );
};

export default App;
