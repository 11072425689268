import { useState, useContext } from "react";
// UI
import Container from "../../components/Container/Container";
import "./Header.css";

// header component
import Logo from "./Logo";

// context
import { UserInFoContext } from "../../context/UserContext";

const Header = () => {
  const { isLoggedIn, logoutHandler } = useContext(UserInFoContext);

  return (
    <header className={`header relative py-4`}>
      <Container className="px-mainContainer">
        <div
          className={`flex flex-col gap-y-4 lg:flex-row lg:items-center lg:justify-between`}
        >
          <Logo />

          {isLoggedIn && (
            <button
              onClick={logoutHandler}
              className="bg-blue text-white px-4 py-2 rounded-xl w-max ml-auto"
            >
              Logout
            </button>
          )}
        </div>
      </Container>
    </header>
  );
};

export default Header;
