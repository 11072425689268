import { useState, useEffect, useContext } from "react";
import Container from "../../../../../components/Container/Container";
import Spinner from "../../../../../components/UI/Spinner";
import CustomForm from "../../Custom Form/CustomForm";
import CIF_Individual_Form_content from "./Inputs Data/CIF-Individual";
import FATCA_Individual_Form_content from "./Inputs Data/FATCA-individual";

//
import CustomUploadFrom from "../../Custom Form/CustomUploadFrom";
import Upload_File_Form_conent from "./Inputs Data/UploadFile-Individual";
import MoveForms from "../../MoveForms";

import performGetFormStatus from "../../../../../utils/account and forms/GetFormStatus";
import { UserInFoContext } from "../../../../../context/UserContext";

const IndividualRoute = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState("");
  const [failed, setFailed] = useState(false);
  const [formStatus, setFormStatus] = useState(null);
  const { userData } = useContext(UserInFoContext);

  const token = userData?.token;

  const formSubmittedHandler = (value) => {
    setFormSubmitted(value);
  };

  // useEffect to get the formStatus and save into formStatus state, empty dependencis to render each time the component is mount
  useEffect(() => {
    const getFormStatus = async () => {
      try {
        setIsLoading(true);
        const data = await performGetFormStatus(token);
        if (data.status_code === 200) {
          setFormStatus(data.data);
        } else {
          setFormStatus(null);
        }
      } catch (error) {
        console.log(`failed ${error}`);
        setFailed(true);
      } finally {
        setIsLoading(false);
      }
    };

    getFormStatus();
  }, []);

  useEffect(() => {
    if (formStatus?.cif_is_done && !formStatus?.fatca_is_done) {
      setFormSubmitted("Move-To-FATCA");
    } else if (formStatus?.cif_is_done && formStatus?.fatca_is_done) {
      setFormSubmitted("Move-To-Upload");
    } else if (formStatus?.document_is_done) {
    }
  }, [formStatus]);

  const renderFormContent = () => {
    if (formSubmitted === "CIF") {
      return (
        <MoveForms
          CIF={true}
          onClick={formSubmittedHandler}
          message="You have successfully filled out the CIF form. Now we are moving on to the FATCA form. Please click to proceed and begin filling out the form."
        />
      );
    } else if (formSubmitted === "FATCA") {
      return (
        <MoveForms
          FATCA={true}
          onClick={formSubmittedHandler}
          message="You have successfully filled out the FATCA form. Now we are moving on to the Upload documents form. Please click to proceed and begin filling out the form."
        />
      );
    } else if (formSubmitted === "Upload") {
      return (
        <MoveForms
          UPLOAD={true}
          onClick={formSubmittedHandler}
          message="
              You have successfully filled out the upload form. Now you have completed all the required forms. Thank you."
        />
      );
    } else if (
      formSubmitted === "Move-To-Upload" &&
      !formStatus?.document_is_done
    ) {
      return (
        <CustomUploadFrom
          onSubmit={formSubmittedHandler}
          formInputs={Upload_File_Form_conent}
        />
      );
    } else if (
      formSubmitted === "Move-To-FATCA" &&
      !formStatus?.fatca_is_done
    ) {
      return (
        <CustomForm
          formInputs={FATCA_Individual_Form_content}
          FATCA={true}
          onSubmit={formSubmittedHandler}
        />
      );
    } else if (formSubmitted === "" && !formStatus?.cif_is_done && !failed) {
      return (
        <CustomForm
          onSubmit={formSubmittedHandler}
          CIF={true}
          formInputs={CIF_Individual_Form_content}
        />
      );
    } else if (
      formStatus?.cif_is_done &&
      formStatus?.fatca_is_done &&
      formStatus?.document_is_done
    ) {
      return (
        <MoveForms
          DONE={true}
          onClick={formSubmittedHandler}
          message="
          Thank you for your interest in our services! You've already completed the registration process. If you need to make any changes or updates to your information, please feel free to contact our support team. Otherwise, feel free to explore the rest of our website for more information and resources.
"
        />
      );
    } else if (failed) {
      return (
        <div className="text-center text-xl text-font">
          Failed to load forms, please refresh the page and try again.
        </div>
      );
    }
  };

  return (
    <main className="my-secondary lg:my-primary">
      <Container className="px-smallContainer lg:px-mainContainer">
        {isLoading ? (
          <div className="flex flex-col items-center justify-center">
            <Spinner />

            <p>The forms are loading</p>
          </div>
        ) : (
          renderFormContent()
        )}
      </Container>
    </main>
  );
};

export default IndividualRoute;
