const performSaveData = async (url, token, formData) => {
  try {
    const response = await fetch(
      `https://arkcapital2022.my.salesforce.com/services/apexrest/FXCM/${url}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      }
    );
    const responseData = await response.json();

    if (!response.ok) {
      console.log("Failed to Save data with Salesforce", responseData);
      return responseData;
    } else {
      console.log("Save data successful", responseData);
      return responseData;
    }
  } catch (error) {
    console.log(error);
    throw new Error("Failed to perform Save data.");
  }
};

export default performSaveData;
