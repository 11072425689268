import LogoImage from "assests/images/logo/logo-blue-grey.png";

const Logo = () => {
  return (
    <div className="w-[14rem] lg:w-[26rem]">
      <img src={LogoImage} alt="" />
    </div>
  );
};

export default Logo;
