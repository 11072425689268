import { useContext, useState } from "react";
// UI
import Spinner from "../../../../components/UI/Spinner";
import BlueBox from "../../UI/BlueBox";
// custom hook
import useInput from "../../../../form/Components/Hooks/user-input";
// input
import Input from "../../../../form/Components/Inputs/Input";
import PasswordInput from "../../../../form/Components/Inputs/PasswordInput";
// error message
import inputFromMessage from "../../../../form/Components/Messages/inputErrorMessage";
import {
  DisplayFormMessgae,
  DisplayFormMessageWithLink,
  DisplayErrorMessage,
} from "../../../../form/Components/Messages/DisplayFormMessgae";

// login components
import LoginVerfication from "./LoginVerfication";
// context
import { UserInFoContext } from "../../../../context/UserContext.js";
import { IsVerfiedContext } from "../../../../context/IsVerfiedContext";

// Fetch Utlies
import performLogin from "../../../../utils/Registartion/Login";

// rest password
// import RestPassword from "../Rest Password/RestPassword";

const LoginForm = ({ onTogleForms }) => {
  // messages
  const [errorMessage, setErrorMessage] = useState("");
  // to toggle the form and open verfied form
  const [isNotVerfied, setIsNotVerfied] = useState(false);
  // spinner
  const [isLoading, setIsloading] = useState(false);
  // context
  const { userInfoHandler } = useContext(UserInFoContext);
  const { setIsUserVerfied } = useContext(IsVerfiedContext);

  // style of inputs
  const inputStyle =
    "border border-darkGreyFont w-full p-2 placeholder:text-sm focus:outline-blue";

  // clear form message when user start typing
  const clearErrorMessage = () => {
    setErrorMessage("");
  };

  // inputs
  const {
    value: emailInput,
    isValid: emailIsValid,
    HasError: emailHasError,
    inputChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    inputFocusHandler: emailFocusHandler,
    isFocus: emailIsFocus,
    reset: emailReset,
  } = useInput((value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
  });
  const {
    value: passwordInput,
    isValid: passwordIsValid,
    HasError: passwordHasError,
    inputChangeHandler: passwordChangeHandler,
    inputBlurHandler: passwordBlurHandler,
    inputFocusHandler: passwordFocusHandler,
    isFocus: passwordIsFocus,
    reset: passwordReset,
  } = useInput((value) =>
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(value.trim(""))
  );

  // check if inputs is valid
  let formIsValid = emailIsValid && passwordIsValid;

  // submit handler
  const formSubmitHandler = async (event) => {
    event.preventDefault();

    if (!formIsValid) {
      // if inputs are  not valud or empty inputs run error and return
      setErrorMessage("empty");
      return;
    }

    const signinState = {
      email: emailInput,
      password: passwordInput,
    };

    try {
      clearErrorMessage("");
      // loding spinner
      setIsloading(true);
      // call the API
      const data = await performLogin(signinState);

      // handle the response
      if (data.status_code === 401) {
        setErrorMessage("wrong-credentials");
      } else {
        const loginData = data.data;
        console.log(loginData);
        // if user is not verified we set isNotVerfied to true to move to verified form
        if (loginData.status === 0) {
          setIsNotVerfied(true);
        } else if (loginData.fxcm === 0) {
          setErrorMessage("not-fxcm");
        } else {
          // if user verfied to logged in
          userInfoHandler(data.data);
          setIsUserVerfied(true);
        }
      }
    } catch (error) {
      // if an error accure fron API error or internet connextion
      setErrorMessage("failed");
    } finally {
      setIsloading(false);
    }
  };

  return (
    <div
      className={`flex flex-col lg:flex-row gap-secondary py-secondary lg:py-primary transition-opacity ease-in duration-1000`}
    >
      {/* blue box change it's content based on the forms (sign in) or (verfied)  */}
      <BlueBox
        title={isNotVerfied ? "Verification Form" : "Login Form"}
        text={
          isNotVerfied
            ? "Your email is not verified. Please verify your email to proceed with the process"
            : "Login start/continue your process"
        }
      />

      {/* by default isNotVeried is false so w render the sign in form to let the user sign in */}
      {!isNotVerfied && (
        <div className="flex-1 lg:p-14">
          {errorMessage === "failed" && (
            <DisplayFormMessageWithLink
              message={
                "An error occurred either due to your internet connection or from our engine. Please try again. If the error persists, please contact us at"
              }
              link={"cs@ark-cap.com"}
            />
          )}

          {errorMessage === "not-fxcm" && (
            <DisplayErrorMessage
              message={
                "Your account is Not FXCM. Please go to the link below and continue your process there"
              }
              link={"https://ark-cap.com"}
            />
          )}

          {errorMessage === "wrong-credentials" && (
            <DisplayFormMessgae
              message={"The email or password is incorrect, please try again."}
            />
          )}
          {errorMessage === "empty" && (
            <DisplayFormMessgae
              message={
                "Kindly ensure that all form inputs are properly filled, as the provided information appears to be invalid."
              }
            />
          )}

          <form className="flex flex-col gap-6" onSubmit={formSubmitHandler}>
            <Input
              boxStyle={""}
              inputStyle={inputStyle}
              type={"email"}
              name={"email"}
              id={"email"}
              placeholder={"Your Email"}
              value={emailInput}
              onChange={(event) => {
                emailChangeHandler(event);
                clearErrorMessage();
              }}
              onBlur={emailBlurHandler}
              onFocus={emailFocusHandler}
              isFocus={emailIsFocus}
              hasError={emailHasError}
              errorMessage={inputFromMessage.email}
            />
            <PasswordInput
              inputStyle={inputStyle}
              type={"password"}
              name={"password"}
              id={"password"}
              placeholder={"Your password"}
              value={passwordInput}
              onChange={(event) => {
                passwordChangeHandler(event);
                clearErrorMessage();
              }}
              onBlur={passwordBlurHandler}
              hasError={passwordHasError}
              onFocus={passwordFocusHandler}
              isFocus={passwordIsFocus}
              errorMessage={inputFromMessage.passwordSignUp}
            />
            <span className="flex items-center justify-en mt-2 mb-2">
              <button className="bg-newBlue hover:bg-blue transition ease-in duration-100 px-4 py-2 text-white rounded-md  tracking-wide flex items-center gap-x-2">
                {isLoading && <Spinner />}
                Login in
              </button>
            </span>
          </form>

          <div className="flex flex-col gap-y-4">
            <span className="text-sm">
              Don't have an Account?{" "}
              <button
                className="text-blue underline"
                onClick={() => onTogleForms("sign-up")}
              >
                Create account
              </button>
            </span>
            <button
              className="flex text-blue text-sm underline"
              onClick={() => onTogleForms("reset-password")}
            >
              Reset Password
            </button>
          </div>
          {/* <RestPassword /> */}
        </div>
      )}

      {/* if user isNotVefied, we render the verfication form to verfi it's email */}
      {isNotVerfied && emailInput.length !== 0 && (
        <LoginVerfication email={emailInput} />
      )}
    </div>
  );
};

export default LoginForm;
