import { useState } from "react";
// number input
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
// UI
import Spinner from "../../../../components/UI/Spinner";
// forms Components
import SignupVerfication from "./SignupVerfication";
import BlueBox from "../../UI/BlueBox";
// custom hook
import useInput from "../../../../form/Components/Hooks/user-input";
import useConfirmPassword from "../../../../form/Components/Hooks/use-confirm-password";
// input
import Input from "../../../../form/Components/Inputs/Input";
import PasswordInput from "../../../../form/Components/Inputs/PasswordInput";
// error message
import inputFromMessage from "../../../../form/Components/Messages/inputErrorMessage";
import {
  DisplayFormMessgae,
  DisplayFormMessageWithLink,
} from "../../../../form/Components/Messages/DisplayFormMessgae";

import perfomSignUp from "../../../../utils/Registartion/SignUp";

const SignupForm = ({ onTogleForms }) => {
  //  for inputs messages
  const [errorMessage, setErrorMessage] = useState("");
  const [APIError, setAPIerror] = useState({
    email: "",
    phone: "",
  });
  // state when form is sucess
  const [isFormSuccess, setIsFormSuccess] = useState(false);
  // default country for phone input
  const defaultCountry = "AE";
  // for loading spiner
  const [isLoading, setIsLoading] = useState(false);

  // style of inputs
  const inputStyle =
    "border border-darkGreyFont w-full p-2 placeholder:text-sm focus:outline-blue";

  // clear form message when user start typing
  const clearErrorMessage = () => {
    setErrorMessage("");
    setAPIerror({
      email: "",
      phone: "",
    });
  };

  const {
    value: firstNameInput,
    isValid: firstNameIsValid,
    HasError: firstNameHasError,
    inputChangeHandler: firstNameChangeHandler,
    inputBlurHandler: firstNameBlurHandler,
    inputFocusHandler: firstNameFocusHanlder,
    isFocus: firstNameIsFocus,
    reset: firstNameReset,
  } = useInput((value) => value.trim() !== "");

  const {
    value: lastNameInput,
    isValid: lastNameIsValid,
    HasError: lastNameHasError,
    inputChangeHandler: lastNameChangeHandler,
    inputBlurHandler: lastNameBlurHandler,
    inputFocusHandler: lastNameFocusHanlder,
    isFocus: lastNameIsFocus,
    reset: lastNameReset,
  } = useInput((value) => value.trim() !== "");

  const {
    value: emailInput,
    isValid: emailIsValid,
    HasError: emailHasError,
    inputChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    inputFocusHandler: emailFocusHandler,
    isFocus: emailIsFocus,
    reset: emailReset,
  } = useInput((value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
  });

  const [phoneValue, setPhoneValue] = useState("");

  const {
    value: passwordInput,
    isValid: passwordIsValid,
    HasError: passwordHasError,
    inputChangeHandler: passwordChangeHandler,
    inputBlurHandler: passwordBlurHandler,
    inputFocusHandler: passwordFocusHandler,
    isFocus: passwordIsFocus,
    reset: passwordReset,
  } = useInput((value) =>
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(value.trim(""))
  );

  const {
    value: confirmPasswordInput,
    isValid: confirmPasswordIsValid,
    HasError: confirmPasswordHasError,
    inputChangeHandler: confirmPasswordChangeHandler,
    inputBlurHandler: confirmPasswordBlurHandler,
    inputFocusHandler: confirmPasswordFocusHanlder,
    isFocus: confirmPasswordIsFocus,
    reset: confirmPasswordReset,
  } = useConfirmPassword(passwordInput);

  let formIsValid =
    firstNameIsValid &&
    lastNameIsValid &&
    emailIsValid &&
    phoneValue &&
    passwordIsValid &&
    confirmPasswordIsValid;

  const formSubmitHandler = async (event) => {
    event.preventDefault();

    if (!formIsValid) {
      // if inputs are  not valud or empty inputs run error and return
      setErrorMessage("empty");
      return;
    }
    const signupState = {
      first_name: firstNameInput,
      last_name: lastNameInput,
      email: emailInput,
      phone: phoneValue,
      password: passwordInput,
      password_confirmation: confirmPasswordInput,
      fxcm: 1,
    };

    try {
      setIsLoading(true);
      const data = await perfomSignUp(signupState);
      if (data.status_code === 422) {
        setIsLoading(false);
        setErrorMessage("API-errors");
        const errors = data.errors;
        const errorObj = errors[0];

        Object.keys(errorObj).forEach((field) => {
          const errorMessage = errorObj[field][0]; // Assuming each error array has only one error message
          setAPIerror((prevAPIError) => ({
            ...prevAPIError,
            [field]: errorMessage,
          }));
        });

        errorObj.email && emailReset();
        errorObj.phone && setPhoneValue("");
      } else {
        setIsFormSuccess(true);
        console.log(data);
      }
    } catch (error) {
      // if an error accure fron API error or internet connextion
      setErrorMessage("failed");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      className={`flex  ${
        isFormSuccess ? "flex-col" : "flex-col-reverse"
      } lg:flex-row gap-14 py-secondary lg:py-primary`}
    >
      {/* when formIsSuccess so the user correctly sigun yp we hide the form and we move into verfication process to verfied his email */}
      {!isFormSuccess && (
        <div className="flex-1 lg:p-14">
          {errorMessage === "API-errors" && (
            <DisplayFormMessgae message={"Please check the form for errors"} />
          )}
          {errorMessage === "failed" && (
            <DisplayFormMessageWithLink
              message={
                "An error occurred either due to your internet connection or from our engine. Please try again. If the error persists, please contact us at"
              }
              link={"cs@ark-cap.com"}
            />
          )}
          {errorMessage === "empty" && (
            <DisplayFormMessgae
              message={
                "Kindly ensure that all form inputs are properly filled, as the provided information appears to be invalid."
              }
            />
          )}

          <form onSubmit={formSubmitHandler} className="flex flex-col gap-6">
            <span className="grid grid-cols-2 gap-x-4 w-full">
              <Input
                boxStyle={"w-full"}
                inputStyle={inputStyle}
                type={"text"}
                name={"firstname"}
                id={"firstname"}
                placeholder={"First Name "}
                value={firstNameInput}
                onChange={(event) => {
                  firstNameChangeHandler(event);
                  clearErrorMessage();
                }}
                onBlur={firstNameBlurHandler}
                onFocus={firstNameFocusHanlder}
                isFocus={firstNameIsFocus}
                hasError={firstNameHasError}
                errorMessage={inputFromMessage.text}
              />
              <Input
                boxStyle={"w-full"}
                inputStyle={inputStyle}
                type={"text"}
                name={"lastname"}
                id={"lastname"}
                placeholder={"Last Name "}
                value={lastNameInput}
                onChange={(event) => {
                  lastNameChangeHandler(event);
                  clearErrorMessage();
                }}
                onBlur={lastNameBlurHandler}
                onFocus={lastNameFocusHanlder}
                isFocus={lastNameIsFocus}
                hasError={lastNameHasError}
                errorMessage={inputFromMessage.text}
              />
            </span>
            <Input
              boxStyle={""}
              inputStyle={inputStyle}
              type={"email"}
              name={"email"}
              id={"email"}
              placeholder={" Email"}
              value={emailInput}
              onChange={(event) => {
                emailChangeHandler(event);
                clearErrorMessage();
              }}
              onBlur={emailBlurHandler}
              onFocus={emailFocusHandler}
              isFocus={emailIsFocus}
              hasError={emailHasError}
              errorMessage={inputFromMessage.email}
              apiErrorMessage={APIError.email}
            />
            <span>
              <PhoneInput
                className={`border border-darkGreyFont w-full p-2 placeholder:text-sm focus:outline-transparent`}
                country="US"
                placeholder="Enter phone number"
                value={phoneValue}
                onChange={setPhoneValue}
                defaultCountry={defaultCountry}
              />
              {APIError.phone ? (
                <p className="text-red-500 text-xs my-1">
                  The phone number has already been taken.
                </p>
              ) : (
                ""
              )}
            </span>

            <PasswordInput
              inputStyle={inputStyle}
              type={"password"}
              name={"password"}
              id={"password"}
              placeholder={" Password"}
              value={passwordInput}
              onChange={(event) => {
                passwordChangeHandler(event);
                clearErrorMessage();
              }}
              onBlur={passwordBlurHandler}
              hasError={passwordHasError}
              onFocus={passwordFocusHandler}
              isFocus={passwordIsFocus}
              errorMessage={inputFromMessage.passwordSignUp}
            />
            <PasswordInput
              inputStyle={inputStyle}
              name={"confirmPassword"}
              id={"confirmPassword"}
              placeholder={"Confirm password"}
              value={confirmPasswordInput}
              onChange={(event) => {
                confirmPasswordChangeHandler(event);
                clearErrorMessage();
              }}
              onBlur={confirmPasswordBlurHandler}
              onFocus={confirmPasswordFocusHanlder}
              isFocus={confirmPasswordIsFocus}
              hasError={confirmPasswordHasError}
              errorMessage={inputFromMessage.passwordConfirm}
            />
            <span className="flex items-center justify-en mt-6 mb-2">
              <button className="bg-newBlue hover:bg-blue transition ease-in duration-100 px-4 py-2 text-white rounded-md  tracking-wide flex items-center gap-x-2">
                {isLoading && <Spinner />}
                Create account
              </button>
            </span>
          </form>

          <span className="text-sm">
            Have an account? {""}
            <button
              className="text-blue underline"
              onClick={() => onTogleForms("sign-in")}
            >
              Log in
            </button>
          </span>
        </div>
      )}

      {/* blue box will be in both form, so we only make the condition to it's content */}
      <BlueBox
        title={!isFormSuccess ? "Sign up Form" : "Verification Form"}
        text={
          !isFormSuccess
            ? "To begin, we will gather essential information to establish your ARKCapital profile. This profile will enable you to register and open your trading account."
            : "Fill in the verification code you've received in your email to verify your account"
        }
        textTwo={
          !isFormSuccess
            ? "By utilizing this account, you can conveniently save your progress and seamlessly continue at a later time."
            : ""
        }
      />

      {/* when isFormSuccess we move to the verfication form and we pass the email as a props in case he need to resned the verfication code */}
      {isFormSuccess && <SignupVerfication email={emailInput} />}
    </div>
  );
};

export default SignupForm;
