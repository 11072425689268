import { IsVerfiedProvider } from "./IsVerfiedContext";
import { UserInfoProvicer } from "./UserContext";
import { FormStateProvider } from "./FormStateContext";
import { SalesForceProvider } from "./SalesForceContext";

const ContextProvider = ({ children }) => {
  return (
    <IsVerfiedProvider>
      <UserInfoProvicer>
        <FormStateProvider>
          <SalesForceProvider>{children}</SalesForceProvider>
        </FormStateProvider>
      </UserInfoProvicer>
    </IsVerfiedProvider>
  );
};

export default ContextProvider;
