import { useState, useEffect, useRef, useContext } from "react";
import Spinner from "../../../../components/UI/Spinner";

import { UserInFoContext } from "../../../../context/UserContext";

// utilities
import performConfirmCode from "../../../../utils/Registartion/ConfimCode";
import performResendCode from "../../../../utils/Registartion/ResendCode";

import {
  DisplayFormMessgae,
  DisplayFormMessageWithLink,
  DisplaySuccsefullMessage,
} from "../../../../form/Components/Messages/DisplayFormMessgae";

const SignupVerfication = ({ email }) => {
  // context
  const { userInfoHandler } = useContext(UserInFoContext);

  // input ref
  const codeInputs = useRef([]);
  // for tracking timer
  const [timer, setTimer] = useState(60);
  // spiner
  const [isLoading, setIsLoading] = useState(false);

  // for tracking verfication code inputs
  const [verificationCode, setVerificationCode] = useState(["", "", "", ""]);
  // form
  const [formMessage, setFormMessage] = useState("");
  const [isFormSuccess, setIsFormSucess] = useState(false);

  const clearHandler = () => {
    setIsFormSucess(false);
    setFormMessage("");
  };

  // for handling timer
  useEffect(() => {
    let intervalId;

    if (timer > 0) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [timer]);

  // for handling inputs when timer finish
  useEffect(() => {
    if (timer === 0) {
      codeInputs.current.forEach((input) => {
        input.disabled = true;
        input.style.opacity = "0.5";
      });
    }
  }, [timer]);

  // for handling inputs changes
  const handleVerificationCodeChange = (e, index) => {
    const value = e.target.value;

    setVerificationCode((prevCode) => {
      const newCode = [...prevCode];
      newCode[index] = value;

      return newCode;
    });

    if (value !== "" && index < codeInputs.current.length - 1) {
      codeInputs.current[index + 1].focus();
    }
  };

  // to rensed verfication code
  const handleResendCode = async () => {
    // setIsformError("");
    const resendCodeState = {
      email: email,
    };
    if (timer === 0) {
      setVerificationCode(["", "", "", ""]);
      setTimer(60);

      codeInputs.current.forEach((input) => {
        input.disabled = false;
        input.style.opacity = "1";
      });
    }

    clearHandler("");
    if (!email) return;
    try {
      setIsLoading(true);
      const data = await performResendCode(resendCodeState);

      if (data.status_code === 422) {
        setFormMessage("new-code-invalid-data");
      } else {
        setFormMessage("new-code-success");
      }
    } catch (error) {
      setFormMessage("failed");
    } finally {
      setIsLoading(false);
    }
  };

  // to submit verfiction
  const handleSubmit = async (e) => {
    e.preventDefault();

    // if (!email) return;
    if (verificationCode.join("") === "") return;

    const confirmCodeState = {
      email: email,
      code: verificationCode.join(""),
    };

    console.log(confirmCodeState.code);

    try {
      setIsLoading(true);
      clearHandler();
      const data = await performConfirmCode(confirmCodeState);
      console.log(data);
      if (data.status_code === 422) {
        setFormMessage("cofirm-code-invalid-data");
      } else {
        userInfoHandler(data.data);
      }
    } catch (error) {
      setFormMessage("failed");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex-1 flex flex-col lg:p-14">
      <div className="mb-10">
        <h1 className="text-2xl  text-font">Verfied Your account</h1>
        {/* different UI message to handle error */}
        {isFormSuccess === "new-code" && (
          <p className="text-sm text-green-500 mt-1">
            The code has been sent successfully. Please check your email and
            enter the new code into the input field
          </p>
        )}
        {formMessage === "cofirm-code-invalid-data" && (
          <DisplayFormMessgae
            message={
              "The code you have entered is incorrect. Please double-check the code and try again"
            }
          />
        )}
        {formMessage === "new-code-invalid-data" && (
          <DisplayFormMessgae
            message={
              "Failed to send new code. Kindly ensure that you are using a valid email address"
            }
          />
        )}
        {formMessage === "failed" && (
          <DisplayFormMessageWithLink
            message={
              "An error occurred either due to your internet connection or from our engine. Please try again. If the error persists, please contact us at "
            }
            link={"cs@ark-cap.com"}
          />
        )}
      </div>

      <form className="flex flex-col justify-center">
        {/* default UI when the user reguster to verfied it's email */}
        <div className="flex items-center  space-x-8">
          {Array.from({ length: 4 }).map((_, index) => (
            <div
              key={index}
              className="w-16 h-16 bg-white border border-gray-200 rounded-lg flex items-center justify-center shadow-md"
            >
              <input
                ref={(el) => (codeInputs.current[index] = el)}
                type="text"
                id={`code-${index}`}
                name={`code-${index}`}
                value={verificationCode[index] || ""}
                onChange={(e) => {
                  handleVerificationCodeChange(e, index);
                  setFormMessage("");
                }}
                maxLength={1}
                className="w-full h-full text-center text-xl bg-transparent outline-none"
              />
            </div>
          ))}
        </div>

        <div className="flex items-center gap-x-3 mt-10">
          {/* when time is 0 a new button apear to resend the code and reset the process and timer */}
          {timer === 0 && (
            <button
              type="button"
              className="bg-blue px-4 py-2 text-white rounded-md tracking-wide flex items-center gap-x-2"
              onClick={handleResendCode}
            >
              Resend Code {isLoading && <Spinner />}
            </button>
          )}

          {/* when time is bigger then a confirm buttin and timer */}
          {timer > 0 && (
            <button
              onClick={handleSubmit}
              className="bg-blue px-4 py-2 text-white rounded-md tracking-wide flex items-center gap-x-2"
            >
              Confirm {isLoading && <Spinner />}
            </button>
          )}
          {timer > 0 && (
            <div className="flex items-center gap-x-4">
              <p className="text-font text-sm">
                Time Remaining: {timer} seconds
              </p>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default SignupVerfication;
